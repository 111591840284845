// Import the functions you need from the SDKs you need
import axios from "axios";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC0C2Vdypo95gsmY02XR1KDmmtq4d1FLIY",
  authDomain: "lmss-29085.firebaseapp.com",
  databaseURL: "https://lmss-29085-default-rtdb.firebaseio.com",
  projectId: "lmss-29085",
  storageBucket: "lmss-29085.appspot.com",
  messagingSenderId: "969052581821",
  appId: "1:969052581821:web:765fa965cd885f1fe4ecd4",
  measurementId: "G-9EG4NVED1P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const requestPermission = () => {
  console.log("request");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("granted!");
      return getToken(messaging, {
        vapidKey:
          "BFCxEfwegS1L0Dzip-IhiBrqwpc4zSoS8t14Z0BuezfXiN70QvvB2pFFfsKhFml74Dj6AWgB9_Pivne_YJykM2k",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current  " + currentToken);
            localStorage.setItem("FCM", currentToken);
            const token = localStorage.getItem("token");
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            axios
              .post(
                "https://lms.almas.education/api/setToken",
                {
                  token_device: currentToken,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                  },
                }
              )
              .then((response) => {
                console.log("Token sent to the backend:", response.data);
              })
              .catch((error) => {
                console.error("Error sending token to the backend:", error);
              });
          } else {
            console.log("failed");
          }
        })
        .catch((err) => {
          console.log("error token ", err);
        });
    } else {
      console.log("user undefined");
    }
  });
};
requestPermission();
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log(payload);
    });
  });
