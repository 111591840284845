import { Input, Typography, Button } from "antd";
import "./ExamQuestionType2.scss";
import { Checkbox } from "antd";

import { useNavigate } from "react-router-dom";
const ExamQuestionType2 = () => {
  const navigate = useNavigate();
  return (
    <div className="examContainer">
      <div className="header">
        <b className="number">1</b>
        <p className="divided">/23</p>
        <div className="counter" style={{ paddingLeft: 10 }}>
          <h2>فحص الدورة</h2>
        </div>
      </div>
      <div className="textArea">
        <h2 className="hh2">عن ماذا تتكلم الدورة ؟</h2>
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 20 }}
        >
          <Checkbox className="Check">الخيار الاول </Checkbox>
          <Checkbox className="Check">الخيار الثاني </Checkbox>
          <Checkbox className="Check">الخيار الثالث</Checkbox>
        </div>
      </div>
      <div className="downButton">
        <Button
          type="primary"
          size="large"
          style={{ width: "16%" }}
          onClick={() => {
            navigate("/ExamQuestionType3");
          }}
        >
          التالي
        </Button>

        <Button
          size="large"
          type="dashed"
          style={{ width: "16%", color: "#3D5CFF", backgroundColor: "#F1F1F1" }}
        >
          اعادة تعين الاجوبة
        </Button>
      </div>
    </div>
  );
};
export default ExamQuestionType2;
