import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Card, Typography } from "antd";

import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import {
  selectAllCourses,
  selectAllCoursesStatus,
  selectAllCoursesError,
} from "../../redux/slices/coursesSlice";
import "./Achievements.scss";
import CoursesSettings from "../../components/Settings/CoursesSettings";

const gridStyle = {
  width: "30%",
  textAlign: "left",
  boxShadow: "none",
  padding: 12,
  //marginTop: 10,
  //paddingBottom: 0,
  //height: 50,
};
const Achievements = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector(selectAllCourses);
  const status = useSelector(selectAllCoursesStatus);
  const error = useSelector(selectAllCoursesError);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  let rendCourse;
  const handleViewClick = (course) => {
    //setCourseTitle(course.title);
    //setSelectedCourse(course);
    navigate(`/Video/${course.id}`);
  };

  return (
    <Home>
      <div className="Achi">
        <div className="cards">
          <Typography className="courses-title">المناهج المكتملة</Typography>
          <div className="cards-container">
            <div className="card">
              <Typography className="course-title">سكيتش من الصفر</Typography>
              <Typography className="course-description">12/5/2023 </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="Vector 1.svg"
                  alt=""
                  style={{ zIndex: 1, position: "relative", right: 12 }}
                />
                <img src="whiteCircul.svg" alt="" />
              </div>
            </div>
            <div className="card">
              <Typography className="course-title">سكيتش من الصفر</Typography>
              <Typography className="course-description">12/5/2023 </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="Vector 1.svg"
                  alt=""
                  style={{ zIndex: 1, position: "relative", right: 12 }}
                />
                <img src="whiteCircul.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <CoursesSettings /> */}
      </div>
    </Home>
  );
};
export default Achievements;
