import { Popconfirm } from 'antd'

export default function DeletePopConfirm({ title, desc, children, onConfirm }) {

    return (
        <Popconfirm
            title={title}
            description={desc}
            onConfirm={onConfirm}
            okText="Yes"
            cancelText="No"
        >
            {children}
        </Popconfirm>
    )
}
