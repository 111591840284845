import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Typography, App } from "antd";
import { CheckOutlined, SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import { LeftOutlined } from "@ant-design/icons";
import {
  fetchAllCourses,
  selectAllCourses,
} from "../../redux/slices/coursesSlice";
import { Avatar } from "antd";
import "./ExploreCuerces.scss";

const Courses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector(selectAllCourses);
  const [selectedCard, setSelectedCard] = useState(null);
  const [courseName, setCourseName] = useState("عنوان الدورة");
  const [description, setDescription] = useState(
    "اختر دورة لتتعرف على التفاصيل"
  );
  const role = localStorage.getItem("role");
  const { notification } = App.useApp();

  const openNotification = () => {
    notification.info({
      message: "اختر الدورة اولا",

      icon: (
        <SmileOutlined
          style={{
            color: "#108ee9",
          }}
        />
      ),
    });
  };
  useEffect(() => {
    const loadCourses = async () => {
      await dispatch(fetchAllCourses());
    };

    loadCourses();
  }, [dispatch]);

  // const loadCourses = async () => {
  //   try {
  //     if (status === "idle") {
  //       await dispatch(fetchAllCourses());
  //     }
  //   } catch (error) {
  //     console.error("Error fetching courses:", error);
  //   }
  // };

  // useEffect(() => {
  //  // loadCourses();
  // }, [dispatch, status]);

  let rendCourse;
  const handleViewClick = (course) => {
    //setCourseTitle(course.title);
    //setSelectedCourse(course);
    navigate(`/Video/${course.id}`);
  };
  if (Array.isArray(courses[1])) {
    rendCourse = courses[1].map((course) => (
      <Card
        className={`card-info ${selectedCard === course.id ? "selected" : ""}`}
        key={course.id}
        style={{ height: "fit-content" }}
        onClick={() => {
          setDescription(course.description);
          setSelectedCard(course.id);
          setCourseName(course.title);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 21,
            boxShadow: "none",
            position: "relative",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Typography className="course-title">{course.title}</Typography>
            <Typography className="course-description">
              {course.capacity} ساعة من الفيديوهات التعليمية
            </Typography>
          </div>
          <Typography className="course-students">423 طالب</Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "50%",
            width: "90%",
            padding: 5,
            textAlign: "left",
          }}
        >
          <Avatar.Group size={30}>
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=3" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=4" />
          </Avatar.Group>
          <b style={{ fontSize: 25 }}>..</b>
        </div>
      </Card>
      // src={`https://lms.karam-mustafa.com/public/storage/${course.image}`}
    ));
  }

  return (
    <Home>
      <div className="containerExplore">
        <div className="cards">
          <Typography className="courses-title">كل الدروس</Typography>
          {rendCourse}
        </div>

        <div className="leftSettings">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
              height: "20%",
              marginRight: 20,
              justifyContent: "space-around",
              // border: "solid 1px red",
              // paddingBottom: 10,
              marginTop: 25,
            }}
          >
            <Typography className="left-title">التفاصيل</Typography>
            <Typography className="course-description">
              {description}
            </Typography>
            <Typography className="left-title">الدروس و الاختبارات</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "27%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#AFD5FF",
                    width: "80%",
                    height: "90%",
                    borderRadius: 30,
                    position: "relative",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <Button
                    icon={
                      <CheckOutlined style={{ color: "#fff", fontSize: 25 }} />
                    }
                    style={{
                      backgroundColor: "#AFD5FF",
                      position: "absolute",
                      bottom: 4,
                      borderRadius: 18,
                      border: "none",
                      boxShadow: "none",
                    }}
                    size="large"
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">{courseName}</Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    if (selectedCard === null) {
                      openNotification();
                    } else if (localStorage.getItem("role") === "4") {
                      navigate(`/Addlesson/${selectedCard}`);
                    } else if (localStorage.getItem("role") === "5") {
                      navigate(`/course/${selectedCard}`);
                    }
                  }}
                >
                  الدرس
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "27%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#F9B8AB",
                    width: "80%",
                    height: "90%",
                    borderRadius: 30,
                    position: "relative",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F7F7F7",
                borderRadius: 20,
                width: "50%",
                height: "75%",
              }}
            >
              <img src="ci.svg" alt="" />
            </div> */}
                  <Button
                    icon={
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: 20,
                          backgroundColor: "#fff",
                        }}
                      />
                    }
                    style={{
                      backgroundColor: "#fff",
                      position: "absolute",
                      top: 12,
                      borderRadius: 18,
                      boxShadow: "none",
                      border: "none",
                    }}
                    size="small"
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">{courseName}</Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    if (selectedCard === null) {
                      openNotification();
                    } else if (role === "4") {
                      navigate(`/Addlesson`);
                    } else if (role === "5") {
                      navigate("/live");
                    }
                  }}
                >
                  بث مباشر
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
            <Card
              style={{
                marginRight: 20,
                border: "none",
                borderRadius: 16,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
                padding: 15,
                width: "80%",
                height: "27%",
              }}
            >
              <Card.Grid
                hoverable={false}
                style={{
                  padding: 0,
                  display: "flex",
                  // justifyContent: "center",
                  boxShadow: "none",
                  width: "20%",
                  // height: "100%",
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#2F3CED",
                    width: "80%",
                    height: "90%",
                    borderRadius: 30,
                    position: "relative",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F7F7F7",
                borderRadius: 20,
                width: "50%",
                height: "75%",
              }}
            >
              <img src="ci.svg" alt="" />
            </div> */}
                  <Button
                    icon={
                      <img
                        src="assignment.svg"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          //borderRadius: 18,
                          left: 4,
                        }}
                      />
                    }
                    style={{
                      backgroundColor: "#2F3CED",
                      position: "absolute",
                      bottom: 12,
                      borderRadius: 18,
                      border: "none",
                      boxShadow: "none",
                    }}
                  />
                </div>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "60%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // height: "10%",
                  padding: 0,
                }}
              >
                <Typography className="live-title">{courseName}</Typography>
                <Button
                  type="text"
                  style={{
                    width: "40%",
                    textAlign: "right",
                    fontSize: 12,
                    color: "#808191",
                    padding: 0,
                  }}
                  onClick={() => {
                    if (selectedCard === null) {
                      openNotification();
                    } else if (localStorage.getItem("role") === "4") {
                      navigate(`/Addlesson/${selectedCard}`);
                    } else {
                      navigate(`/ExamQuestionType1`);
                    }
                  }}
                >
                  اختبار
                </Button>
              </Card.Grid>
              <Card.Grid
                hoverable={false}
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                  boxShadow: "none",
                  marginTop: 5,
                }}
              >
                <LeftOutlined />
              </Card.Grid>
            </Card>{" "}
          </div>
          {role === "4" && (
            <>
              <div
                style={{
                  marginRight: 20,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: 45,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#2F3CED",
                    color: "#fff",
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                    // fontFamily: "Inter",
                    borderRadius: 16,
                    width: "35%",
                  }}
                  size="large"
                  //type="primary"
                  onClick={() => {
                    if (selectedCard === null) {
                      openNotification();
                    } else {
                      navigate(`/Addlesson/${selectedCard}`);
                    }
                  }}
                >
                  اضافة درس
                </Button>
                <Button
                  size="large"
                  style={{
                    color: "#2F3CED",
                    backgroundColor: "#DDEDFF80",
                    border: "1px solid #2F3CED",
                    borderRadius: 16,
                    width: "35%",
                    // boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  onClick={() => {
                    navigate(`/NewExam/${selectedCard}`);
                  }}
                >
                  اضافة اختبار
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 20,
                  marginLeft: 50,
                }}
              >
                <Button
                  size="large"
                  style={{
                    color: "#2F3CED",
                    backgroundColor: "#DDEDFF80",
                    border: "1px solid #2F3CED",
                    borderRadius: 16,
                    width: "60%",
                    // fontFamily: "Inter",
                    // boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  onClick={() => {
                    if (selectedCard === null) {
                      openNotification();
                    } else {
                      navigate(`/AddLiveLesson`);
                    }
                  }}
                >
                  اضافة بث مباشر مجدول
                </Button>
              </div>
            </>
          )}
          {selectedCard && (
            <Button
              type="text"
              style={{ color: "#EE4323" }}
              onClick={() => {
                navigate(`/course/${selectedCard}`);
              }}
            >
              عرض المزيد
            </Button>
          )}
        </div>
      </div>
    </Home>
  );
};
export default Courses;
