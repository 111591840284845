import Dashboard from "./pages/dashboard/Dashboard";
import VideoPlay from "./pages/videoplay/VideoPlay";
import LiveSession from "./pages/livesession/LiveSession";
import Login from "./pages/login/Login";
import ExamQuestionType1 from "./pages/examquestiontype1/ExamQuestionType1";
import ExamQuestionType2 from "./pages/examquestiontype2/ExamQuestionType2";
import ExamQuestionType3 from "./pages/examquestiontype3/ExamQuestionType3";
import Exams from "./pages/exams/Exams";
import Profile from "./pages/profile/Profile";
import ExploreCuerces from "./pages/explorecuerces/ExploreCuerces";
import Messenger from "./pages/messenger/Messenger";
import TeacherDashboard from "./pages/dashboard/teacherdashboard";
import { Route, Routes, useNavigate } from "react-router-dom";
import UnAuth from "./pages/unAuth/unAuth";
import { useEffect } from "react";
import AddNewExam from "./pages/addNewExam/AddNewExam";
import AddExamUpload from "./pages/addNewExam/AddExamUpload";
import AddNewCuerce from "./pages/addNewCuerce.jsx/AddNewCuerce";
import Courses from "./pages/explorecuerces/Courses";
import CorrectAnswers from "./pages/correctAnswers/CorrectAnswers";
import FinalExam from "./pages/FinalExam/FinalExam";
import SingleCuerce from "./pages/singlecuerce/SingleCuerce";
import NewLiveLesson from "./pages/newLiveLesson/NewLiveLesson";
import AddNewLesson from "./pages/addNewLesson/AddNewLesson";
import Students from "./pages/students/Students";
import Tests from "./pages/tests/Tests";
import Achievements from "./pages/Achievements/Achievements";
import Questions from "./pages/addNewExam/Qestions";
import EditExam from "./pages/EditExam/EditExam";
import Privacypolicy from "./pages/privacyPolicy/privacyPolicy";
import AntdConfigProvider from "./components/config/AntdConfigProvider";

function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isAuthenticated = !!token;
  const isLogedIn = window.localStorage.getItem("isLogedIn");
  useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== "/") {
      navigate("/UnAuthorized");
    }
  }, [isAuthenticated, navigate]);
  const role = localStorage.getItem("role");

  return (
    <AntdConfigProvider>
      <Routes>
        <Route
          path="/"
          element={
            isLogedIn && role === "4" ? (
              <TeacherDashboard />
            ) : isLogedIn && role === "5" ? (
              <Dashboard />
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route path="/UnAuthorized" element={<UnAuth />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
      </Routes>
      {isAuthenticated && (
        <Routes>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Dashboard_t" element={<TeacherDashboard />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Video/:id" element={<VideoPlay />} />
          <Route
            path="/ExamQuestionType1/:id"
            element={<ExamQuestionType1 />}
          />
          <Route path="/ExamQuestionType2" element={<ExamQuestionType2 />} />
          <Route path="/ExamQuestionType3" element={<ExamQuestionType3 />} />
          <Route path="/Exams" element={<Exams />} />
          {/* <Route path="/ExamAnswersUpload" element={<ExamAnswersUpload />} /> */}
          <Route path="/ExploreCourses" element={<ExploreCuerces />} />
          <Route path="/NewExam/:id" element={<AddNewExam />} />
          {/* <Route path="/Progress" element={<Progressing />} /> */}
          <Route path="/UploadExam/:id" element={<AddExamUpload />} />
          <Route path="/NewCourse" element={<AddNewCuerce />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/Messenger" element={<Messenger />} />
          <Route
            path="/CorrectAnswers/:examId/:id"
            element={<CorrectAnswers />}
          />
          <Route path="/FinalExam" element={<FinalExam />} />
          <Route path="/Live" element={<LiveSession />} />
          <Route path="/course/:id" element={<SingleCuerce />} />
          <Route path="/AddLiveLesson/:id?" element={<NewLiveLesson />}></Route>
          {/* <Route path="/edit-live-lesson/:id?" element={<NewLiveLesson />}></Route> */}
          <Route path="/AddLesson/:id" element={<AddNewLesson />}></Route>
          <Route path="/Students/:id" element={<Students />} />
          <Route path="/Tests" element={<Tests />} />
          <Route path="/Achievements" element={<Achievements />}></Route>
          <Route path="/Questions" element={<Questions />}></Route>
          <Route path="/EditExam/:id" element={<EditExam />}></Route>
        </Routes>
      )}
    </AntdConfigProvider>
  );
}

export default Home;
