import React from "react";
import { Button, Dropdown, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Link } = Typography;

export default function AddButtonsActions({
  id,
  setShowAddLiveSessionForm,
  setShowAddExamForm,
  setShowAddLessonForm,
}) {
  const navigate = useNavigate();

  let url = "";
  if (localStorage.getItem("role") === "4") {
    url = `/NewExam/${id}`;
  } else if (localStorage.getItem("role") === "5") {
    url = `/ExamQuestionType1`;
  }
  const items = [
    {
      key: "1",
      label: (
        <Link
          onClick={() => {
            setShowAddLiveSessionForm(false);
            setShowAddExamForm(false);
            setShowAddLessonForm(true);
          }}
        >
          اضافة درس
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          onClick={() => {
            setShowAddLiveSessionForm(false);
            setShowAddLessonForm(false);
            setShowAddExamForm(true);
          }}
        >
          اضافة اختبار
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          onClick={() => {
            setShowAddLessonForm(false);
            setShowAddExamForm(false);
            setShowAddLiveSessionForm(true);
          }}
        >
          اضافة بث مباشر مجدول
        </Link>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} placement="bottomLeft">
      <Button type={"text"} className="card-clicked-effect">
        ..
      </Button>
    </Dropdown>
  );
}
