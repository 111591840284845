import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants"

const API_PROFILE_URL = `${API_BASE_URL}/student/profile`;

const API_EDIT_PROFILE_URL =
  `${API_BASE_URL}/student/profile/edit`;

export const fetchUserProfile = createAsyncThunk(
  "profile/fetchUserProfile",
  async () => {
    try {
      const response = await axios.get(API_PROFILE_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log(response.data);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const editUserProfile = createAsyncThunk(
  "profile/editUserProfile",
  async (data) => {
    try {
      const formData = new FormData();
      formData.append("_method", "put");
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("age", data.age);
      formData.append("photo", data.photo);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("web", true);
      const response = await axios.post(API_EDIT_PROFILE_URL, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(data);
      throw error;
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (data) => {
    try {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("bio", data.bio);
      formData.append("age", data.age);
      formData.append("qualifications", data.qualifications);
      formData.append("experience", data.experience);
      formData.append("phone", data.phone);
      formData.append("first_name", data.users.first_name);
      formData.append("last_name", data.users.last_name);
      formData.append("email", data.users.email);
      formData.append("photo", data.users.photo);
      formData.append("expertise", data.expertise);
      formData.append("web", true);
      const response = await axios.post(
        `${API_BASE_URL}/teacher/profile/edit`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTeacherProfile = createAsyncThunk(
  "profile/fetchTeacherProfile",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/teacher/profile`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      //  console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    error: null,
    user: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(editUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the response from the editUserProfile API call if needed
      })
      .addCase(editUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchTeacherProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeacherProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchTeacherProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the response from the updateProfile API call if needed
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export default profileSlice.reducer;
