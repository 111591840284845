import React from "react";
import { Card, Button, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";

export default function ExamCard({
  display,
  exam,
  selectedCard,
  openNotification,
  navigate,
}) {
  return (
    <Card
      key={exam?.id}
      style={{
        marginRight: 20,
        border: "none",
        borderRadius: 16,
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
        padding: 8,
        width: "80%",

        display: `${display}`,
      }}
    >
      <Card.Grid
        hoverable={false}
        style={{
          padding: 0,
          display: "flex",
          boxShadow: "none",
          width: "22%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          icon={<img src="assignment.svg" style={{}} />}
          style={{
            backgroundColor: "#2F3CED",
            borderRadius: 18,
            border: "none",
            boxShadow: "none",
          }}
          size="large"
          type="circle"
        />
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{
          width: "55%",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 0,
        }}
      >
        <Typography className="live-title">{exam?.title}</Typography>
        <Button
          type="text"
          style={{
            width: "40%",
            textAlign: "right",
            fontSize: 12,
            color: "#808191",
            padding: 0,
          }}
          onClick={() => {
            if (selectedCard === null) {
              openNotification();
            } else if (localStorage.getItem("role") === "4") {
              navigate(`/NewExam/${selectedCard}`);
            } else {
              navigate(`/ExamQuestionType1/${exam?.id}`);
            }
          }}
        >
          اختبار
        </Button>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{
          width: "20%",
          display: "flex",
          flexDirection: "row",
          padding: 0,
          justifyContent: "flex-end",
          alignItems: "flex-start",
          boxShadow: "none",
          marginTop: 5,
        }}
      >
        <LeftOutlined />
      </Card.Grid>
    </Card>
  );
}
