import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  Typography,
  DatePicker,
  message,
  Upload,
} from "antd";
import {
  InboxOutlined,
  UploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import moment from "moment";
import Home from "../home/Home";
import { useDataContext } from "../../context/DataContext";
import PageTitle from "../../components/Title/PageTitle";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Dragger } = Upload;

const AddNewExam = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [totalMarks, setTotalMarks] = useState(null);
  const { questionData, setQuestionDataContext } = useDataContext();

  const [messageApi, contextHolder] = message.useMessage();
  const { id: questionId } = useParams();

  const dispatch = useDispatch();
  const { id } = useParams();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "تمت اضافة سؤال",
    });
  };
  useEffect(() => {
    if (questionData) {
      // Populate form fields with data from questionData
      setTitle(questionData.title);
      setDescription(questionData.description);
      setStartTime(moment(questionData.startDateTimeString));
      setEndTime(moment(questionData.endDateTimeString));
      setTotalMarks(questionData.totalMarks);
    }
  }, [questionData]);

  const handleAddQuestionClick = () => {
    if (
      title.trim() === "" ||
      description.trim() === "" ||
      startDateTimeString === null ||
      endDateTimeString === null ||
      totalMarks === null
    ) {
      message.error("يرجى تعبية الحقول للاضافة سؤال.");
      return;
    }

    // Create an object with the data to pass
    const questionData = {
      title,
      description,
      startDateTimeString,
      endDateTimeString,
      totalMarks,
      id,
    };

    // Update the shared context with the question data
    setQuestionDataContext(questionData);

    // Navigate to the Questions page
    navigate("/Questions");
  };

  const navigate = useNavigate();
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  };
  const startDateTimeString = startTime ? formatDateTime(startTime) : null;
  const endDateTimeString = endTime ? formatDateTime(endTime) : null;
  return (
    <Home>
      <div className="ExamContainer">
        {contextHolder}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 0,
            height: "100%",
            flex: 10,
          }}
        >
          <PageTitle>إضافة إختبار</PageTitle>

          <Form>
            <div style={{ marginTop: 0, marginLeft: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 20,
                }}
              >
                <Typography className="question-numbers">
                  معلومات الاختبار
                </Typography>
              </div>
              <Form.Item
                rules={[
                  { required: true, message: "العنوان مطلوب" },
                  { min: 0, max: 255 },
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: 20,
                  }}
                >
                  <Typography className="related-title">العنوان</Typography>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="اكتب العنوان"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "الشرح مطلوب" },
                  { min: 0, max: 255 },
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: 20,
                  }}
                >
                  <Typography className="related-title">شرح </Typography>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="شرح الاختبار"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: 20,
                  }}
                >
                  <RangePicker
                    showTime
                    size="large"
                    style={{ width: "100%" }}
                    value={[startTime, endTime]}
                    onChange={(dates) => {
                      setStartTime(dates[0]);
                      setEndTime(dates[1]);
                    }}
                  />
                  <Typography className="related-title">
                    الوقت البداية والنهاية
                  </Typography>
                </div>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "50%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="related-title">
                    {" "}
                    العلامة كاملة
                  </Typography>
                  <Input
                    type="number"
                    size="large"
                    style={{ width: "66%" }}
                    placeholder="علامة كاملة للاختبار"
                    value={totalMarks}
                    onChange={(e) => setTotalMarks(e.target.value)}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="text"
                    icon={
                      <PlusCircleOutlined
                        style={{ fontSize: 20, color: "#2F3CED" }}
                      />
                    }
                    size="small"
                    style={{ border: "none" }}
                    onClick={() => {
                      if (handleAddQuestionClick() === true) {
                        navigate("/Questions");
                      }
                    }}
                  ></Button>
                  <Typography className="related-title">إضافة سؤال</Typography>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>

        <div
          style={{
            backgroundColor: "#DDEDFF80",
            display: "flex",
            flex: "5",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        ></div>
      </div>
    </Home>
  );
};

export default AddNewExam;
