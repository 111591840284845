import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants"
const initialState = {
  status: "idle",
  video: [],
  error: null,
};

export const uploadVideoSlice = createAsyncThunk(
  "video/uploadVideoSlice",
  async ({ data, id }) => {
    try {
      const apiUrl = `${API_BASE_URL}/teacher/upload-video/${id}`;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(apiUrl, data, config);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const updateTopic = createAsyncThunk(
  "video/updatevideoSlice",
  async (data) => {
    try {
      console.log('====================================');
      console.log("id", data.id);
      console.log("data", data);
      console.log('====================================');
      const apiUrl = `${API_BASE_URL}/teacher/topics/${data.id}`;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(apiUrl, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadVideoSlice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadVideoSlice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.video = action.payload;
      })
      .addCase(uploadVideoSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(updateTopic.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTopic.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.video = action.payload;
      })
      .addCase(updateTopic.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const selectVideo = (state) => state.video.video;
export default videoSlice.reducer;
