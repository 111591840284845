import { Typography, Button } from "antd";
import "./ExamQuestionType3.scss";

import React, { useState } from "react";
import { Input, Radio, Space } from "antd";
import { useNavigate } from "react-router-dom";
const ExamQuestionType3 = () => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const navigate = useNavigate();
  return (
    <div className="examContainer">
      <div className="header">
        <b className="number">1</b>
        <p className="divided">/23</p>
        <div className="counter" style={{ paddingLeft: 10 }}>
          <h2>فحص الدورة</h2>
        </div>
      </div>
      <div className="textArea">
        <h2 className="hh2">عن ماذا تتكلم الدورة ؟</h2>
        <Radio.Group onChange={onChange} value={value}>
          <Space className="Check" direction="vertical">
            <Radio value={1}> A الخيار </Radio>
            <Radio value={2}> B الخيار </Radio>
            <Radio value={3}>C الخيار </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="downButton">
        <Button
          type="primary"
          size="large"
          style={{ width: "16%" }}
          onClick={() => {
            navigate("/video/5");
          }}
        >
          التالي
        </Button>
        <Button
          size="large"
          type="dashed"
          style={{ width: "16%", color: "#3D5CFF", backgroundColor: "#F1F1F1" }}
        >
          اعادة تعين الاجوبة
        </Button>
      </div>
    </div>
  );
};
export default ExamQuestionType3;
