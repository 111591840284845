import React from "react";
import { Card, Typography, Flex } from "antd";
export default function StudentCourseCard({ selectedCard, course, onClick }) {
  return (
    <Card
      className={`card-info ${selectedCard === course.id ? "selected" : ""}`}
      key={course.id}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "100%",
          padding: 21,
          boxShadow: "none",
          position: "relative",
          justifyContent: "space-between",
          //  backgroundColor: "RED",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <Typography className="course-title">{course.title}</Typography>
          <Typography className="course-description">
            {course.capacity} ساعة من الفيديوهات التعليمية
          </Typography>
        </div>
        {/* <Typography className="course-students">423 طالب</Typography> */}
      </div>
      <Flex
        justify="space-between"
        alignItems="start"
        style={{ padding: "10px 20px ", height: "fit-content" }}
      >
        <Typography.Title
          className="card-clicked-effect"
          level={5}
          type="secondary"
          style={{ padding: "0", margin: "0", fontSize: "12px" }}
        >
          {course?.videos_count} طلاب
        </Typography.Title>
        <Typography.Title
          className="card-clicked-effect"
          level={5}
          style={{ padding: "0", margin: "0" }}
        >
          ..
        </Typography.Title>
      </Flex>
    </Card>
  );
}
