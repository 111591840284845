import React, { createContext, useContext, useState } from "react";

const CourseContext = createContext();

export function useCourseContext() {
  return useContext(CourseContext);
}

export function CourseProvider({ children }) {
  const [courseTitle, setCourseTitle] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);

  return (
    <CourseContext.Provider
      value={{ courseTitle, setCourseTitle, selectedCourse, setSelectedCourse }}
    >
      {children}
    </CourseContext.Provider>
  );
}
