import React from "react";
import { Layout, Space } from "antd";
import Navbar from "../../components/common/navbar/Navbar";
import SideBar from "../../components/common/sidebar/sideBar";
import "./home.scss";

const { Content } = Layout;

const Home = ({ children }) => (
  <Layout style={{ direction: "rtl", backgroundColor: "#fff" }}>
    <SideBar />

    <Layout>
      <Navbar />
      <Content
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="containerDashboard">{children}</div>
      </Content>
    </Layout>
  </Layout>
);
export default Home;
