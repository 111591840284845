import "./VideoPlay.scss";
import { Card, Typography, Button, List, FloatButton, Result } from "antd";
import "antd/dist/reset.css";
import { SmileOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import {
  fetchCoursesVideos,
  selectCourseVideos,
  selectStatus,
  selectError,
} from "../../redux/slices/coursesVideosSlice";
import {
  fetchSingleCourse,
  selectSingleCourse,
} from "../../redux/slices/coursesSlice";
import { useCourseContext } from "../../context/CourseContext";
import {
  StarOutlined,
  CaretRightOutlined,
  PauseOutlined,
  LockFilled,
} from "@ant-design/icons";
import Vimeo from "@u-wave/react-vimeo";
const gridStyle = {
  width: "100%",

  boxShadow: "none",
  padding: "14px",
  textAlign: "right",
};
const data = [
  "Welcome to the Course.",
  "Process overview.",
  "Exam.",
  "Live Session.",
];

const VideoPlay = ({ videoId }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedCourse } = useCourseContext();
  const dispatch = useDispatch();
  const courseVideos = useSelector(selectCourseVideos);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const singleCourse = useSelector(selectSingleCourse);
  let r;
  //let selectedVideoUrl = ""; // To store the selected video URL
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);

  //console.log(courseVideos);
  const handlePlayVideo = (url) => {
    if (url) {
      setSelectedVideoUrl(url);
      setIsPlaying(true);
    } else {
      // Display a message when there i s no video URL
      alert("There is no video available.");
    }
  };
  const handleVideoClick = (videoUrl) => {
    if (videoUrl) {
      setCurrentVideoUrl(videoUrl);
    } else {
      alert("Video URL not found.");
    }
  };
  const loadCourse = async () => {
    try {
      if (status === "idle") {
        await dispatch(fetchCoursesVideos(id));
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  // localStorage.setItem("title ", selectedCourse?.title);
  const loadSingle = async () => {
    try {
      if (status === "idle") {
        await dispatch(fetchSingleCourse(id));
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  useEffect(() => {
    loadSingle();
    loadCourse();
  }, [dispatch, status, id]);

  console.log(singleCourse);
  //console.log(currentVideoUrl);

  return (
    <div className="container">
      {" "}
      <div className="player">
        {currentVideoUrl ? ( // Check if a video URL is selected
          <VideoPlayer id={null} videoUrl={currentVideoUrl}></VideoPlayer>
        ) : (
          <div
            style={{
              display: "block",
              justifyContent: "center ",
              width: "100%",
              height: "100%",

              padding: 20,
            }}
          >
            <Result icon={<SmileOutlined />} title="اختر فيديو لنبدأ التعلم " />
          </div>
        )}
      </div>
      <div className="details">
        <Card
          size="small"
          style={{
            width: "400px",
            height: "200px",
            marginBottom: "20px",
            borderRadius: "25px",
          }}
        >
          <Card.Grid style={gridStyle} hoverable={false}>
            <h3 className="head">
              {selectedCourse ? selectedCourse.title : "دورة لغة الانجليزية"}
            </h3>
          </Card.Grid>

          <Card.Grid
            hoverable={false}
            style={{
              width: "100%",
              boxShadow: "none",
              padding: "10px",
              textAlign: "right",
            }}
          >
            <b className="courseTitle">تفاصيل الدورة</b>
          </Card.Grid>
          <Card.Grid
            hoverable={false}
            style={{
              width: "100%",
              boxShadow: "none",
              padding: "10px",
              textAlign: "right",
            }}
          >
            <Typography className="description">
              {selectedCourse
                ? selectedCourse.description
                : "من الصفر الى الاحتراف"}
            </Typography>
          </Card.Grid>
        </Card>

        <Card
          size="small"
          style={{
            width: "400px",
            height: "350px",
            marginBottom: "20px",
            borderRadius: "25px",
            overflow: "scroll",
          }}
        >
          <div style={{ padding: 10 }}>
            <h3 className="title">الدورات </h3>
          </div>
          <Card.Grid
            style={{
              width: "100%",
              boxShadow: "none",
              padding: 10,
            }}
            hoverable={false}
          >
            <List
              style={{}}
              dataSource={courseVideos.course_videos}
              renderItem={(item) => (
                <List.Item className="listItem" style={{ padding: 10 }}>
                  <Typography.Text
                    style={{
                      color: "#B8B8D2",
                      fontSize: "25px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    {item.id}
                  </Typography.Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <Typography.Text>{item.title}</Typography.Text>

                    <p className="time"> دقيقة {item.length} </p>
                  </div>
                  <FloatButton
                    icon={
                      isPlaying ? <PauseOutlined /> : <CaretRightOutlined />
                    }
                    type="primary"
                    onClick={() => handleVideoClick(item.url)} // Play the selected video
                    style={{
                      position: "relative",
                      bottom: 0,
                      right: 0,
                      //   margin: 20,
                    }}
                  />
                </List.Item>
              )}
            />

            <List.Item
              className="listItem"
              style={{
                padding: 12,
                //border: "solid 1px black",
                position: "relative",
              }}
            >
              <Typography.Text
                style={{
                  color: "#B8B8D2",
                  fontSize: "25px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                10
              </Typography.Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  textAlign: "center",
                  //  border: "solid 1px black",
                  width: "80%",
                }}
              >
                <Typography.Text style={{ position: "relative", left: 10 }}>
                  مذاكرة
                </Typography.Text>

                <p className="time"> 10 دقيقة </p>
              </div>
              <FloatButton
                icon={<LockFilled />}
                type="primary"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  margin: 12,
                  //top: 50,
                }}
                onClick={() => {
                  navigate("/ExamQuestionType1");
                }}
              />
            </List.Item>
          </Card.Grid>
          {/* <Card.Grid
            hoverable={false}
            style={{
              width: "100px",
              boxShadow: "none",
              height: "100px",
              padding: 10,
            }}
          > */}
          {/* <FloatButton
              icon={<PauseOutlined />}
              type="primary"
              style={{
                position: "relative",
                bottom: 0,
                right: 0,
                margin: 20,
                top: 30,
              }}
            />
            <FloatButton
              icon={<LockFilled />}
              type="primary"
              style={{
                position: "relative",
                bottom: 0,
                right: 0,
                margin: "20px ",
                top: 50,
              }}
            /> */}
          {/* </Card.Grid> */}
        </Card>
        {/* <div className="button">
          <Button
            style={{
              backGround: "#FFEBF0",
              width: "30%",
            }}
            icon={<StarOutlined style={{ color: "#FF6905" }} />}
            size={"large"}
          />
          <Button
            size={"large"}
            type="primary"
            style={{
              width: "70%",
            }}
          >
            Add To Cart
          </Button>
        </div> */}
      </div>
    </div>
  );
};
export default VideoPlay;
