import "./LiveSession.scss";
import { Card, Typography, Button, Avatar } from "antd";
import "antd/dist/reset.css";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Home from "../home/Home";
const gridStyle = {
  width: "100%",
  height: "50%",
  textAlign: "center",
  backgroundColor: "#FFF",
  margin: "40px",
  borderBottomRightRadius: "20%",
  padding: 10,
};

const LiveSession = () => {
  return (
    <Home>
      <div className="containerLiveSession">
        <div className="detailsLiveSession">
          <Card
            style={{
              width: "100%",
              height: "580px",
              //  marginBottom: "20px",
              borderRadius: "25px",
              padding: 0,
            }}
          >
            <Card.Grid style={gridStyle}>
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  gap: 10,
                  height: "50%",
                }}
              >
                <Avatar
                  icon={<UserOutlined />}
                  size={50}
                  style={{ padding: 0 }}
                />
                <Typography className="userName">Max Lebedev</Typography>
                <Typography className="lastSeen">21m ago</Typography>
              </div>
              <Card.Grid
                style={{
                  width: "100%",
                  padding: 5,
                  display: "flex",
                  height: "20%",
                  textAlign: "center",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                <Typography className="Msg">Thank you.</Typography>
              </Card.Grid>
              <Card.Grid
                style={{
                  width: "100%",
                  padding: 5,
                  display: "flex",
                  height: "20%",
                  textAlign: "center",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                <Button
                  style={{
                    color: "#FF6905",
                    fontSize: "14px",
                    borderColor: "#FF6905",
                    borderRadius: "20px",
                  }}
                >
                  Mute
                </Button>
              </Card.Grid>
            </Card.Grid>
            <Card.Grid
              style={{
                width: "100%",
                padding: "10px",
                position: "absolute",
                // display: "fixed",
                // top: 275,
                border: "none",
                boxShadow: "none",
                bottom: 0,
              }}
            >
              <Input
                placeholder="Type Your Message"
                style={{
                  position: "relative",
                  width: "80%",
                }}
              />
              <Button
                shape="circle"
                style={{ marginLeft: 20, border: "none", color: "#3D5CFF" }}
                icon={<SendOutlined rotate={-45} style={{ fontSize: 25 }} />}
              />
            </Card.Grid>
          </Card>
        </div>
        <div className="playerLiveSession">
          <img className="videoLiveSession" src="singleCourse.png"></img>
        </div>
      </div>
    </Home>
  );
};
export default LiveSession;
