// answerslice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants"

const bearerToken = localStorage.getItem("token");
const apiUrl = `${API_BASE_URL}/teacher/answers`;
// Define the initial state
const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchAnswers = createAsyncThunk(
  "answers/fetchAnswers",
  async (examId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/teacher/responding-students/${examId}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const fetchAnswersByIds = createAsyncThunk(
  "answers/fetchAnswersById",
  async ({ examId, id }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/teacher/answers/${examId}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);
export const createAnswer = createAsyncThunk(
  "answers/createAnswer",
  async ({ examId, id, finalPostData }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/teacher/answers/${examId}/${id}`,
        finalPostData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json", // Adjust content type if needed
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(finalPostData);
      throw error.response ? error.response.data : error.message;
    }
  }
);

// Create the answers slice
const answersSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnswers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAnswers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAnswers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || action.error;
      })
      .addCase(fetchAnswersByIds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAnswersByIds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchAnswersByIds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || action.error;
      })
      .addCase(createAnswer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAnswer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createAnswer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || action.error;
      });
  },
});

// Export the reducer
export default answersSlice.reducer;
