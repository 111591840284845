import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import coursesReducer from "../slices/coursesSlice";
import coursesVideosReducer from "../slices/coursesVideosSlice";
import uploadVideoReducer from "../slices/uploadVideoSlice";
import examReducer from "../slices/Questions"; // Import the examSlice reducer
import answersReducer from "../slices/AnswersSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    courses: coursesReducer,
    coursesVideos: coursesVideosReducer,
    uploadVideo: uploadVideoReducer,
    exam: examReducer,
    answers: answersReducer,
  },
});

export default store;
