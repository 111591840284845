// Create a new file, e.g., DataContext.js
import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [questionData, setQuestionData] = useState(null);

  const setQuestionDataContext = (data) => {
    setQuestionData(data);
  };

  return (
    <DataContext.Provider value={{ questionData, setQuestionDataContext }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
