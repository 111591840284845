import { Table, Card, List, Typography, Button } from "antd";
import "./Exams.scss";
import { useNavigate } from "react-router-dom";
const Exams = () => {
  const columns = [
    {
      title: (
        <div style={{ textAlign: "right" }}>
          <p>التاريخ</p>
        </div>
      ),
      dataIndex: "date",
      key: "date",
      width: "10%",
      rowScope: "row",

      render: (text) => (
        <div style={{ textAlign: "right" }}>
          <p className="date">{text}</p>
        </div>
      ),
    },
    {
      title: (
        <div style={{ textAlign: "right" }}>
          <p>اسم الفحص</p>
        </div>
      ),
      dataIndex: "examName",
      key: "examName",
      width: "50%",
      rowScope: "row",
      render: (text) => (
        <div style={{ textAlign: "right" }}>
          <p className="exam-name">{text}</p>
        </div>
      ),
    },
    {
      title: (
        <div style={{ textAlign: "right" }}>
          <p>العلامة</p>
        </div>
      ),
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      rowScope: "row",
      render: (text) => (
        <div
          className="marks"
          style={{ display: "flex", justifyContent: "flex-end " }}
        >
          <b className="price">{text}</b> <p>/60</p>
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const data = [
    {
      key: "1",
      examName: "Product Design v1.0 ",
      date: "17/9/2023",
      amount: "54",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: "54",
      tags: ["loser"],
    },
    {
      key: "3",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: "54",
      tags: ["cool", "teacher"],
    },
    {
      key: "4",
      examName: "Product Design v1.0 ",
      date: "17/9/2023",
      amount: "54",
      tags: ["nice", "developer"],
    },
    {
      key: "5",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: "54",
      tags: ["loser"],
    },
    {
      key: "6",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: "54",
      tags: ["cool", "teacher"],
    },
    {
      key: "7",
      examName: "Product Design v1.0 ",
      date: "17/9/2023",
      amount: "54",
      tags: ["nice", "developer"],
    },
    {
      key: "8",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: "54",
      tags: ["loser"],
    },
    {
      key: "9",
      examName: "Product Design v1.0",
      date: "17/9/2023",
      amount: `54`,
      tags: ["cool", "teacher"],
    },
  ];
  const settings = ["اعدادات الحساب", "عرض التقدم", "عرض المذاكرات مع النتائج"];
  const handleLogout = async (e) => {
    e.preventDefault();

    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <div className="container">
      <div className="table">
        <Table className="custom-table" columns={columns} dataSource={data} />
      </div>

      <div className="left">
        <Card className="Card">
          <List
            className="list"
            style={{ alignItems: "center", marginBottom: "20px" }}
            dataSource={settings}
            renderItem={(item, index) => (
              <List.Item
                className="list-item"
                style={{
                  padding: "20px",
                  paddingLeft: "0px",
                  fontSize: "13px",
                  display: "flex",
                  borderBottom: "1px solid #AFAFAF",
                  borderTop: index === 0 ? "none" : "1px solid #AFAFAF",
                  backgroundColor: index === 2 ? "#F3F0F0" : "none",
                }}
                onClick={() => {
                  if (item === "عرض التقدم") {
                    navigate("/progress");
                  } else if (item === "اعدادات الحساب") {
                    navigate("/profile");
                  } else if (item === "عرض المذاكرات مع النتائج") {
                    navigate("/Exams");
                  }
                }}
              >
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
          <Button
            style={{
              position: "absolute",
              bottom: 4,
              right: 4,
              border: "none",
              color: "#FF6905",
            }}
            onClick={handleLogout}
          >
            تسجيل الخروج
          </Button>
        </Card>
      </div>
    </div>
  );
};
export default Exams;
