import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  List,
  Skeleton,
  Card,
  Typography,
  Input,
  FloatButton,
  Form,
} from "antd";
import {
  RightOutlined,
  PlusOutlined,
  SendOutlined,
  CloseOutlined,
  CheckOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import "./FinalExam.scss";

import { useNavigate } from "react-router-dom";
import Home from "../home/Home";

const FinalExam = () => {
  const navigate = useNavigate();

  return (
    <div className="correctContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          // border: "1px red solid",
          height: "100%",
          flex: 10,
        }}
      >
        <div className="live-answers">
          <Typography className="related-title">
            نزل الملف و ضع النتيجة النهائية للاختبار
          </Typography>
        </div>

        <Form>
          {" "}
          <div style={{ marginRight: 40, marginTop: 0, marginLeft: 20 }}>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "40%",
                  //  justifyContent: "space-between",
                  //   border: "1px red solid",
                  //  height: "10%",
                  gap: 20,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    //gap: 40,
                    width: "70%",
                    //  border: "1px red solid",
                    padding: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="related-title">
                    ملف الاجابات
                  </Typography>
                  <Button
                    size="large"
                    style={{
                      border: "1px solid #11142D",
                      borderRadius: 12,
                      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                      width: "35%",
                    }}
                  >
                    تنزيل
                  </Button>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "25%",
                  justifyContent: "space-between",
                  gap: 20,
                }}
              >
                <Typography className="related-title">
                  العلامة النهائية
                </Typography>
                <div
                  style={{
                    backgroundColor: "#eef6ff",
                    border: "1px solid #808191",
                    borderRadius: 12,
                    display: "flex",
                    flexDirection: "row",
                    // padding: 5,
                    justifyContent: "space-between",
                    width: "35%",
                    height: "30%",
                    padding: 2,
                  }}
                >
                  <Button
                    shape="circle"
                    icon={
                      <PlusOutlined
                        style={{ color: "#fff", fontWeight: 700 }}
                      />
                    }
                    style={{ backgroundColor: "#11142D" }}
                  ></Button>
                  <Typography className="page-number" style={{ padding: 5 }}>
                    1
                  </Typography>
                  <Button
                    shape="circle"
                    style={{ backgroundColor: "#11142D" }}
                    icon={
                      <MinusOutlined
                        style={{ color: "#fff", fontWeight: 700 }}
                      />
                    }
                  ></Button>
                </div>
              </div>
            </Form.Item>

            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <Button
                style={{
                  backgroundColor: "#11142D",
                  border: "1px solid #A3A3A5",
                  borderRadius: 16,
                  width: "10%",
                  color: "#fff",
                }}
                onClick={() => {
                  navigate("/Dashboard_t");
                }}
                size="large"
              >
                حفظ
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default FinalExam;
