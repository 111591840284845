import React from "react";

export function TopicWatchedIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        transform="matrix(-1 0 0 1 56 0)"
        fill="#AFD5FF"
      />
      <path
        d="M24.3333 38L17 29.9149L19.5667 27.0851L24.3333 32.3404L36.4333 19L39 21.8298L24.3333 38Z"
        fill="white"
      />
    </svg>
  );
}

export function PlayIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        transform="matrix(-1 0 0 1 56 0)"
        fill="#F9B8AB"
      />
      <path
        d="M37.6875 28.5004C37.688 28.7232 37.6309 28.9424 37.5217 29.1366C37.4124 29.3308 37.2548 29.4934 37.0641 29.6087L25.245 36.8389C25.0457 36.9609 24.8175 37.0275 24.5839 37.0318C24.3503 37.0362 24.1197 36.9781 23.9161 36.8635C23.7144 36.7507 23.5464 36.5862 23.4293 36.387C23.3122 36.1878 23.2503 35.9609 23.25 35.7298V21.271C23.2503 21.0399 23.3122 20.8131 23.4293 20.6138C23.5464 20.4146 23.7144 20.2501 23.9161 20.1373C24.1197 20.0228 24.3503 19.9646 24.5839 19.969C24.8175 19.9733 25.0457 20.0399 25.245 20.1619L37.0641 27.3922C37.2548 27.5074 37.4124 27.67 37.5217 27.8642C37.6309 28.0584 37.688 28.2776 37.6875 28.5004Z"
        fill="white"
      />
    </svg>
  );
}

export function PauseIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        transform="matrix(-1 0 0 1 56 0)"
        fill="#F9B8AB"
      />
      <path
        d="M25.4167 38.2913V16.708H19.25V38.2913H25.4167ZM37.75 38.2913V16.708H31.5833V38.2913H37.75Z"
        fill="white"
      />
    </svg>
  );
}

export function ExamIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        transform="matrix(-1 0 0 1 56 0)"
        fill="#2F3CED"
      />
      <mask
        id="mask0_569_37"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="24"
        height="24"
      >
        <rect
          width="24"
          height="24"
          transform="matrix(-1 0 0 1 40 16)"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_569_37)">
        <path
          d="M35 37C35.55 37 36.0208 36.8042 36.4125 36.4125C36.8042 36.0208 37 35.55 37 35V21C37 20.45 36.8042 19.9792 36.4125 19.5875C36.0208 19.1958 35.55 19 35 19H30.8C30.5833 18.4 30.2208 17.9167 29.7125 17.55C29.2042 17.1833 28.6333 17 28 17C27.3667 17 26.7958 17.1833 26.2875 17.55C25.7792 17.9167 25.4167 18.4 25.2 19H21C20.45 19 19.9792 19.1958 19.5875 19.5875C19.1958 19.9792 19 20.45 19 21V35C19 35.55 19.1958 36.0208 19.5875 36.4125C19.9792 36.8042 20.45 37 21 37H35ZM35 35H21V21H35V35ZM33 33H26V31H33V33ZM33 29H23V27H33V29ZM33 25H23V23H33V25ZM28 20.25C27.7833 20.25 27.6042 20.1792 27.4625 20.0375C27.3208 19.8958 27.25 19.7167 27.25 19.5C27.25 19.2833 27.3208 19.1042 27.4625 18.9625C27.6042 18.8208 27.7833 18.75 28 18.75C28.2167 18.75 28.3958 18.8208 28.5375 18.9625C28.6792 19.1042 28.75 19.2833 28.75 19.5C28.75 19.7167 28.6792 19.8958 28.5375 20.0375C28.3958 20.1792 28.2167 20.25 28 20.25Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
