import "./CoursesSettings.scss";
import { Typography, Space } from "antd";
import { useParams } from "react-router-dom";
import TopicCard from "../Cards/TopicCard";

const SingleCoursesSettings = ({ topics, onVideoClick }) => {
  const { id } = useParams();
  return (
    <div className="leftSettings">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginRight: 20,
          justifyContent: "flex-end",
          padding: 20,
        }}
      >
        <Typography className="left-title">الدروس و الاختبارات</Typography>
      </div>
      <Space
        direction="vertical"
        style={{
          height: "75vh",
          overflow: "scroll",
          width: "90%",
          padding: 10,
        }}
      >
        {topics?.map((topic) => (
          <TopicCard id={id} onVideoClick={onVideoClick} topic={topic} />
        ))}
      </Space>
    </div>
  );
};

export default SingleCoursesSettings;
