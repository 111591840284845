import React from "react";
import { Menu, Typography } from "antd";
import { MessageFilled, BellFilled } from "@ant-design/icons";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { Dropdown, Space, Button } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

const headerStyle = {
  display: "flex",
  border: "none",
  gap: 10,
  textDecoration: "none",
  hover: "none",
  flexDirection: "row-reverse",
  position: "sticky",
  top: 0,
  zIndex: 1,
  boxShadow: "  37px 0px 43px -38px rgba(0, 0, 0, 0.04)",
};

const Navbar = () => {
  const role = localStorage.getItem("role");

  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem("token");
  const [isHovered, setIsHovered] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  const handleMenuClick = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  };

  // useEffect(() => {
  //   axios
  //     .get("https://lms.almas.education/api/my-notifications", {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       setNotifications(response.data);
  //       const titles = notifications.map(
  //         (notification) => notification.data.title
  //       );
  //       const msg = notifications.map(
  //         (notification) => notification.data.message
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching notifications:", error);
  //     });
  // }, []);

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        width: "400px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
      }}
    >
      {notifications.map((notification, index) => (
        <Menu.Item key={index}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #A3A3A54F",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography style={{ textAlign: "right", fontWeight: 700 }}>
                {notification.data.title}
              </Typography>

              <Typography
                style={{ textAlign: "right", color: "#808191", fontSize: 10 }}
              >
                {notification.data.message}
              </Typography>
            </div>
          </div>
        </Menu.Item>
      ))}
      <div style={{ padding: 10, width: "100%" }}>
        <Button
          type="text"
          style={{ width: "100%", color: "#2F3CED", fontWeight: 700 }}
        >
          رؤية الكل
        </Button>
      </div>
    </Menu>
  );

  return (
    <>
      <Menu style={headerStyle} mode={"horizontal"}>
        <Menu.Item
          key={"notifications"}
          style={{ borderBottomColor: "#FFF", border: "none" }}
        >
          {notifications.length && !hasInteracted && (
            <div
              style={{
                // backgroundColor: "#EE4323",
                width: 8,
                height: 8,
                borderRadius: 20,
                position: "absolute",
                right: 30,
                top: 15,
              }}
            ></div>
          )}
          <Dropdown
            overlay={menu}
            onMouseEnter={handleMouseEnter}
            onClick={handleMenuClick}
          >
            {/* <a href="#"> */}
            <Space style={{ padding: 5 }}>
              <BellFilled style={{ fontSize: 18 }} />
            </Space>
            {/* </a> */}
          </Dropdown>
        </Menu.Item>
        <Menu.Item key={"/Messenger"} style={{ border: "none" }}>
          <div
            style={{
              backgroundColor: "#EE4323",
              width: 8,
              height: 8,
              borderRadius: 20,
              position: "absolute",
              right: 32,
              top: 15,
            }}
          ></div>
          <Space style={{ padding: 5 }}>
            <MessageFilled
              style={{ fontSize: 18, fontWeight: "bold" }}
              onClick={() => {
                navigate("/Messenger");
              }}
            />
          </Space>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Navbar;
