import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import Home from "../home/Home";
import { requestPermission } from "../../firebase/firebase";
import { API_BASE_URL } from "../../helpers/constants";

const Messenger = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const mapType = ($role) => {
    switch ($role) {
      case "teacher":
        return "t";
      case "student":
        return "s";
      default:
        return "a";
    }
  };
  function getDomainName(url) {
    var parsedUrl = new URL(url);
    var domainName = parsedUrl.hostname;
    domainName = domainName.replace(/^www\./, "");
    return domainName;
  }
  useEffect(() => {
    requestPermission();
    const FCM = localStorage.getItem("FCM");
    const domain = getDomainName(API_BASE_URL);
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const type = mapType(user?.role?.name);
    const name = user?.first_name + " " + user?.last_name;
    const photo = user?.photo;
    setUrl(
      `https://lmss-29085.web.app?id=${user.id}&name=${name}&photo=${photo}&token=${token}&type=${type}&fcm=${FCM}&domain=${domain}`
    );
  }, []);
  const navigateToPreviousPage = () => {
    navigate(-1); // or use navigate('/') to navigate to a specific route
  };

  return (
    <Home>
      <div style={{ width: "100%", height: "100%" }}>
        <iframe
          title="chats"
          style={{ width: "100%", height: "100%" }}
          src={url}
        ></iframe>
      </div>
    </Home>
  );
};

export default Messenger;
