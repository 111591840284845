import React from "react";
import { Typography } from "antd";
import { COMMON_TITLE_STYLING } from "../../helpers/constants";

const { Title } = Typography;
export default function PageTitle({ props, children }) {
  return (
    <Title style={{ ...COMMON_TITLE_STYLING, ...props }}>{children}</Title>
  );
}
