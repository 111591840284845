import { ConfigProvider, notification, App } from "antd";

export default function AntdConfigProvider({ children }) {
  notification.config({
    placement: "bottomRight",
    maxCount: 1,
  });
  return (
    <ConfigProvider>
      <App notification={{ placement: "bottomLeft", maxCount: 1 }}>
        {children}
      </App>
    </ConfigProvider>
  );
}
