import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants";
// const API_BASE_URL = "https://lms.almas.education/api/login";
export const login = createAsyncThunk(
  "auth/login",
  async ({ phone, password }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        phone,
        password,
      });
      console.log(response.data);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("user", JSON.stringify(response.data.user));

      return response.data;
    } catch (error) {
      console.error("Login failed:", error.message);
      localStorage.clear("token");
      throw error.message;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    user: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});
export const selectLogin = (state) => state.auth.auth;
export const selectStatus = (state) => state.login.status;
export const selectError = (state) => state.login.error;
export default authSlice.reducer;
